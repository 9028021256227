import axios from 'axios';

// Global method for measuring Axios request timing and returning the execution time
function measureAxiosRequestTime(apiUrl) {
    
    return new Promise((resolve, reject) => {
        const startTime = performance.now();

        axios.get(apiUrl)
            .then((response) => {
                const endTime = performance.now();
                const executionTime = Math.floor(endTime - startTime);
                // console.log('Axios request completed in ' + executionTime + ' milliseconds');
                resolve({ response, executionTime });
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export default measureAxiosRequestTime;