import React, {useEffect, useRef, useState} from 'react';
import { WOW } from 'wowjs';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'mutationobserver-shim';
/* eslint-disable */
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery; // Make jQuery available globally
require('owl.carousel');

const Footer = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const wowRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            wowRef.current = new WOW({
                live: false
            });
            wowRef.current.init();
        }, 200); // Delay of 1000 milliseconds (1 second)


        const onScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    useEffect(() => {
        if (scrollPosition > 200) {
            $('.back-to-top').fadeIn('slow');
        } else {
            $('.back-to-top').fadeOut('slow');
        }

        if (scrollPosition > 0) {
            $('.navbar').addClass('nav-sticky');
        } else {
            $('.navbar').removeClass('nav-sticky');
        }
    }, [scrollPosition]);

    useEffect(() => {
        $(".testimonials-carousel").owlCarousel({
            center: true,
            autoplay: true,
            dots: true,
            loop: true,
            responsive: {
                0:{
                    items:1
                }
            }
        });
    }, []);


    const currentYear = new Date().getFullYear();
    return (
        <div>
            <div className="footer wow fadeIn" data-wow-delay="0.3s">
                <div className="container-fluid">
                    <div className="container copyright">
                        <p>&copy; All Right Reserved | 2006 - {currentYear}
                        </p>
                    </div>
                </div>
            </div>
            <a href="#" className="btn back-to-top"><i className="fa fa-chevron-up"></i></a>

        </div>
    );
};

export default Footer;