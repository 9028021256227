import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";


const Header = ({ name, title, description }) => {

    return (
        <HelmetProvider>
            <header id="header">
                <Helmet>
                    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
                    <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" rel="stylesheet" />
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css" rel="stylesheet" />
                    <link href="/lib/animate/animate.min.css" rel="stylesheet" />
                    <link href="/lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
                    <link href="/lib/lightbox/css/lightbox.min.css" rel="stylesheet" />
                </Helmet>
            </header>
        </HelmetProvider>
    );
};

export default Header;