import React, { useEffect, useState, useContext } from 'react';
import $ from 'jquery';
import { ApiUrlContext } from './ApiUrlContext';
import measureAxiosRequestTime from './utils';

const Portfolio = () => {
    const { apiUrl } = useContext(ApiUrlContext);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        measureAxiosRequestTime(`${apiUrl}/projects`)
            .then(({ response, executionTime }) => {
                setProjects(response.data);
                $('#execution-time').append(`&nbsp;&nbsp;&nbsp;&nbsp;Projects data fetched in ${executionTime} milliseconds`);
        });

    }, []);
    return (
        <div className="portfolio" id="portfolio">
            <div className="container">
                <div className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                    <p>My Portfolio</p>
                    <h2>My Excellent Portfolio</h2>
                </div>
                <div className="row portfolio-container">
                    {projects.map((project, index) => {
                        return (
                            // <div key={project.id} className="col-lg-4 col-md-6 col-sm-12 portfolio-item">
                            <div key={project.id} className="col-lg-4 col-md-6 col-sm-12 portfolio-item filter-1 wow fadeInUp" data-wow-delay={`${Math.round(0.2)}s`}>
                                <div className="portfolio-wrap">
                                    <div className="portfolio-img">
                                        <img src={`uploads/project/medium/` + project.image } alt="Image" />
                                    </div>
                                    <div className="portfolio-text">
                                        <h3>{project.name}</h3>
                                        {/* Add additional details as needed */}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Portfolio;