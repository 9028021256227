import React, {useState, useEffect} from 'react';
import axios from "axios";

const Contacts = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const clearMessages = () => {
            setSuccessMessage('');
            setErrorMessage('');
        };

        if (successMessage || errorMessage) {
            const timeout = setTimeout(clearMessages, 2000); // Clear messages after 2 seconds

            return () => clearTimeout(timeout); // Clear timeout on component unmount
        }
    }, [successMessage, errorMessage]);
    const sendEmailToAPI = (event) => {
        event.preventDefault(); // Prevent form submission

        if (!name || !email || !subject || !message) {
            setErrorMessage('Please fill in all the fields');
            return;
        }

        axios.post('https://api.vushev.com/contact', {
            name: name, email: email, subject: subject, message: message
        })
            .then(response => {
                setSuccessMessage('Email sent successfully:' + response.data);
            })
            .catch(error => {
                console.log('Error sending email:', error);
            });

    };

    const handleInputChange = (event) => {
        const {id, value} = event.target;

        switch (id) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'subject':
                setSubject(value);
                break;
            case 'message':
                setMessage(value);
                break;
            default:
                break;
        }
    };

    return (<div>
        <div className="contact wow fadeInUp" data-wow-delay="0.1s" id="contact">
            <div className="container-fluid">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4"></div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <div id="success">{successMessage}</div>
                                <div id="error">{errorMessage}</div>
                                <form name="sentMessage" id="contactForm" noValidate="novalidate">
                                    <div className="control-group">
                                        <input type="text" className="form-control" id="name"
                                               placeholder="Your Name" required="required"
                                               data-validation-required-message="Please enter your name"
                                               value={name} onChange={handleInputChange}
                                               autoComplete="name"/>
                                        <p className="help-block"></p>
                                    </div>
                                    <div className="control-group">
                                        <input type="email" className="form-control" id="email"
                                               placeholder="Your Email" required="required"
                                               data-validation-required-message="Please enter your email"
                                               value={email} onChange={handleInputChange}
                                               autoComplete="email"/>
                                        <p className="help-block"></p>
                                    </div>
                                    <div className="control-group">
                                        <input type="text" className="form-control" id="subject"
                                               placeholder="Subject" required="required"
                                               data-validation-required-message="Please enter a subject"
                                               value={subject} onChange={handleInputChange}
                                               autoComplete="subject"/>
                                        <p className="help-block"></p>
                                    </div>
                                    <div className="control-group">
                                        <textarea className="form-control" id="message" placeholder="Message"
                                                  required="required"
                                                  data-validation-required-message="Please enter your message"
                                                  value={message} onChange={handleInputChange}
                                                  autoComplete="off"></textarea>
                                        <p className="help-block"></p>
                                    </div>
                                    <div>
                                        <button className="btn" type="submit" id="sendMessageButton"
                                                onClick={sendEmailToAPI}>Send Message
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
};

export default Contacts;