import React, { useContext } from 'react';
import { ApiUrlContext } from './ApiUrlContext';

const Hero = () => {
    const { handleButtonClick } = useContext(ApiUrlContext);
    return (
        <div className="hero" id="home">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm-12 col-md-6">
                        <div className="hero-content">
                            <div className="hero-text">
                                <p>I'm</p>
                                <h1>Vasil Vushev</h1>
                                <h2>Software Engineer</h2>
                            </div>
                            <div className="hero-btn">
                                Switch between APIs:&nbsp;&nbsp;&nbsp;
                                <button className="btn" onClick={(event) => handleButtonClick(event, "node")}>Node JS</button>
                                <button className="btn" onClick={(event) => handleButtonClick(event, "php")}>PHP</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 d-none d-md-block">
                        {/*<div className="hero-image">*/}
                        {/*    <img src={heroImg} alt="Hero Image" />*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;