import React, { useState, useEffect, useContext } from 'react';
/* eslint-disable */
import jQuery from 'jquery';
import { ApiUrlContext } from './ApiUrlContext';
import measureAxiosRequestTime from './utils';

const Experience = () => {
    const { apiUrl } = useContext(ApiUrlContext);
    const [experienceData, setExperienceData] = useState([]);

    useEffect(() => {
        measureAxiosRequestTime(`${apiUrl}/experience`)
            .then(({ response, executionTime }) => {
                setExperienceData(response.data);
                $('#execution-time').append(`&nbsp;&nbsp;&nbsp;&nbsp;Experience data fetched in ${executionTime} milliseconds`);
            });

    }, []);

    return (
        <div className="experience" id="experience">
            <div className="container">
                <header className="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                    <p>My Resume</p>
                    <h2>Working Experience</h2>
                </header>
                <div className="timeline">
                {experienceData.map((item, index) =>
                    <div className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'} wow slideIn${index % 2 === 0 ? 'Left' : 'Right'}`} data-wow-delay="0.1s" key={index}>
                        <div className="timeline-text">
                            <div className="timeline-date">{item.dates}</div>
                            <h2>{item.position}</h2>
                            <h4>{item.location}</h4>
                            {/*<p>*/}
                                <b>MAIN ACTIVITIES AND RESPONSIBILITIES:</b>
                                <br/>
                                <ul style={{margin: 0, padding: 0, listStylePosition: 'inside'}}>
                                    {
                                        item.responsibilities.map((responsibility, index) => (
                                            <li key={index} style={{marginBottom: '5px'}}>{responsibility}</li>
                                        ))
                                    }
                                </ul>
                            {/*</p>*/}
                            {/*<p><strong>Used technologies and tools:</strong> {item.technologies}</p>*/}
                        </div>
                    </div>
                )}
                </div>
            </div>
        </div>
    );
};

export default Experience;