import './style.css';
import Header from "./components/Header";
import NavBar from "./components/NavBar";
import Hero from "./components/Hero";
import About from "./components/About";
import { ApiUrlProvider } from './components/ApiUrlContext';
import Service from "./components/Service";
import Experience from "./components/Experience";
import Portfolio from "./components/Portfolio";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";
function App() {

    return (
        <div data-spy="scroll" data-target=".navbar" data-offset="51">
            <ApiUrlProvider>
                <Header/>
                <NavBar/>
                <Hero/>
                <About/>
                {/*<Service/>*/}
                <Experience/>
                <Portfolio/>
                <Contacts/>
                <Footer/>
            </ApiUrlProvider>
        </div>
)
    ;
}

export default App;
