import React, { createContext, useState, useEffect } from 'react';

const ApiUrlContext = createContext();

const ApiUrlProvider = ({ children }) => {
    const [apiUrl, setApiUrl] = useState(() => {
        const hashApiUrl = window.location.hash.includes('node') ? 'https://api-node.vushev.com' : null;
        const storedApiUrl = localStorage.getItem('apiUrl');
        const defaultApiUrl = 'https://api.vushev.com';
        return hashApiUrl || storedApiUrl || defaultApiUrl;
    });

    useEffect(() => {
        localStorage.setItem('apiUrl', apiUrl);
    }, [apiUrl]);


    const handleButtonClick = (event, id) => {
        // console.log(id)
        event.preventDefault();
        if (id === "node") {
            setApiUrl('https://api-node.vushev.com');
        } else {
            setApiUrl('https://api.vushev.com');
        }
        window.location.reload();
    };

    return (
        <ApiUrlContext.Provider value={{ apiUrl, handleButtonClick }}>
            {children}
        </ApiUrlContext.Provider>
    );
};

export { ApiUrlProvider, ApiUrlContext };