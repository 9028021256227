import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

const About = () => {
    const skills = [
        { name: 'Languages: PHP, JavaScript:', percentage: '100%', id: 'skill-1' },
        { name: 'Databases: MySQL/MariaDB, Redis, MongoDB, Elasticsearch:', percentage: '100%', id: 'skill-2' },
        { name: 'Frameworks/Libraries: Laravel, React, Vue.js, jQuery, React Native', percentage: '85%', id: 'skill-3' },
        { name: 'Server Administration: AWS, GCP, Docker/Docker-compose, Linux:', percentage: '75%', id: 'skill-4' },
    ];

    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className="about wow fadeInUp" data-wow-delay="0.1s" id="about">
            <Waypoint onEnter={() => setIsVisible(true)}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-text">
                                <p>
                                    I deliver innovative and scalable web solutions, leveraging a comprehensive skillset honed over 19 years. My expertise empowers me to:
                                </p>
                                <p>
                                    - Build secure and high-performance applications: Extensive experience with PHP, databases (MySQL/MariaDB, Redis, MongoDB, Elasticsearch), and server administration (AWS, GCP, Docker) ensures robust and efficient solutions.
                                </p>
                                <p>
                                    - Craft engaging user experiences: I utilize JavaScript frameworks like React, Vue.js, and Electron to create interactive and dynamic user interfaces for web and desktop applications.
                                </p>
                                <p>
                                    - Implement modern development practices: Employ Git for version control and GitLab CI for streamlined continuous integration processes.
                                </p>
                                <p>
                                    - Manage complex projects: Proven experience with popular CMS platforms (WordPress, Magento, etc.) and e-commerce development. I'm also well-versed in Agile and Kanban methodologies for efficient project delivery.
                                </p>
                                <p>
                                    - Optimize website performance and user engagement: In-depth knowledge of SEO best practices and tools (Google Analytics, Webmaster Tools) helps drive website traffic and conversions.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content">
                                <div className="section-header text-left">
                                    <p>Learn About Me</p>
                                    <h2>19 Years Experience</h2>
                                </div>
                                <div className="skills">
                                    {skills.map((skill, index) => (
                                        <div key={index}>
                                            <div className="skill-name">
                                                <p>{skill.name}</p><p>{skill.percentage}</p>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" id={skill.id}
                                                     aria-valuenow={parseInt(skill.percentage, 10)}
                                                     aria-valuemin="0" aria-valuemax="100"
                                                     style={{
                                                         width: isVisible ? skill.percentage : '0%',
                                                         transition: 'width 2s'
                                                     }}>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Waypoint>
        </div>
    );
};

export default About;